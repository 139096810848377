import TeamOne from "../assets/imgs/team/paul_headshot2.png"
import TeamTwo from "../assets/imgs/team/ella_headshot2.png"
import TeamThree from "../assets/imgs/team/cj_headshot2.png"
import TeamFour from "../assets/imgs/team/connor_headshot2.png"
import TeamFive from "../assets/imgs/team/john_headshot2.png"
import TeamSix from "../assets/imgs/team/mikey_headshot3.png"
import FunnyOne from "../assets/imgs/team/paul_funny.jpg"
import FunnyTwo from "../assets/imgs/team/ella_funny.jpg"
import FunnyThree from "../assets/imgs/team/cj_funny.png"
import FunnyFour from "../assets/imgs/team/connor_funny.png"
import FunnyFive from "../assets/imgs/team/john_funny.png"
import FunnySix from "../assets/imgs/team/mikey_funny.jpg"

export const TeamData = [
    {
        id: 1,
        imgs: {
            default: TeamOne,
            flipped: FunnyOne,
        },
        name: "Paul",
        position: "Job Position",
        description: {
            one: "Favorite Cocktail:",
            one_key: "Whiskey sour with egg whites or an eastside",
            two: "Spirit Animal:",
            two_key: "Pottermore told me it's a dolphin but I love octopuses",
            three: "Least Favorite Word/Phrase:",
            three_key: "Cringe",
            four: "Superpower: ",
            four_key: "Winning argument with Karens"
        },
        shadow: "#FFDCDC",
        color: "#E6C0B9"
    },
    {
        id: 2,
        imgs: {
            default: TeamTwo,
            flipped: FunnyTwo,
        },
        name: "Ella",
        position: "Job Position",
        description: {
            one: "Favorite Cocktail:",
            one_key: "Aperol Spritz",
            two: "Spirit Animal: Flying Squirrel",
            two_key: "Flying Squirrel",
            three: "Least Favorite Word/Phrase: ",
            three_key: "It's lit",
            four: "Superpower:",
            four_key: "Invincible to hangovers"
        },
        shadow: "#FFD1C2",
        color: "#F0B1A4"
    },
    {
        id: 3,
        imgs: {
            default: TeamThree,
            flipped: FunnyThree,
        },
        name: "CJ",
        position: "Job Position",
        description: {
            one: "Favorite Cocktail: The one in my hand",
            one_key: "The one in my hand",
            two: "Spirit Animal: Como?",
            two_key: "Como?",
            three: "Least Favorite Word/Phrase:",
            three_key: "Thirst Trap",
            four: "Superpower:",
            four_key: "I'm already perfect so..."
        },
        shadow: "#FEE7B7",
        color: "#E9C289"
    },
    {
        id: 4,
        imgs: {
            default: TeamFour,
            flipped: FunnyFour,
        },
        name: "Connor",
        position: "Job Position",
        description: {
            one: "Favorite Cocktaik:",
            one_key: "Corpse Reviver #2",
            two: "Spirit Animal:",
            two_key: "Dolphin",
            three: "Least Favorite Word/Phrase:",
            three_key: "On God",
            four: "Superpower:",
            four_key: "Shapeshifting"
        },
        shadow: "#F7E0FF",
        color: "#ECB6FF"
    },
    {
        id: 5,
        imgs: {
            default: TeamFive,
            flipped: FunnyFive,
        },
        name: "John",
        position: "Job Position",
        description: {
            one: "Favorite Cocktaik:",
            one_key: "Shrimp",
            two: "Spirit Animal:",
            two_key: "Rat",
            three: "Least Favorite Word/Phrase:",
            three_key: "I’m okay with language evolving and leaving my old ass in the dust. That being said, ‘cheugy’ can go straight to hell.",
            four: "Superpower:",
            four_key: "I think having off-the-charts luck would be the most entertaining and I don’t really have to consider how I use my powers for good, they’re just always doing their thing."
            
        },
        shadow: "#E8FFED",
        color: "#ADF1C0"
    },
    {
        id: 6,
        imgs: {
            default: TeamSix,
            flipped: FunnySix,
        },
        name: "Mikey",
        position: "Job Position",
        description: {
            one: "Favorite Cocktail:",
            one_key: "Old fashioned",
            two: "Spirit Animal:",
            two_key: "Falcon",
            three: "Least Favorite Word/Phrase:",
            three_key: "Fire",
            four: "Superpower: Wealth",
            four_key: "Wealth"
        },
        shadow: "#DFDFF8",
        color: "#A6A4F0"
    },
]