import { Link } from "react-router-dom"
import BestSellers from "../cards/bestsellerscard"
import BestSellersCarousel from "../components/home/bestsellerscarousel"
import SwiperCarousel from "../components/home/swipercarousel"
import SwiperNav from "../features/swipernav"

const Home = ({fanFavs}) => {

    return (
        <div >
            <section id="hero" >
                <div className="container">
                    <div className="row">
                        <div className="tt-header-content col-md-6 p-4">
                            <div className="">
                                <h1 className="fw-bold tt-header-hero-fs" >10% ABV<br/><span className="text-primary">100% FIRE</span></h1>
                                <p className="tt-header-info text-dark">At Thirst Trap we don't take ourselves seriously, but we definitely take our cocktails seriously.</p>
                                <Link to="/storelocator" ><button className="tt-button btn">Find Near You</button></Link>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <img src={require("../assets/imgs/home/header4.jpg")}  className="tt-hero-img img-fluid border border-2 border-dark" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <main id="id" className="">

                <section id="bestsellers" className="d-none d-md-block">
                    <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center mb-5">
                            <h1 className="tt-header-fs fw-bold">WHAT'S <span>HOT</span></h1>
                        </div>
                        <div className="row">
                            {fanFavs && fanFavs.map(product => (
                                <BestSellers key={product.id} product={product} />
                            ))}
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center py-5">
                            <Link to="/products"><button className="tt-button btn">View All Products</button></Link>
                        </div>
                    </div>
                </section>

                <div className="d-md-none d-block">
                    {fanFavs && 
                    <BestSellersCarousel fanFavs={fanFavs} >
                        <SwiperNav/>
                    </BestSellersCarousel>}
                </div>

                <section id="ourproduct">
                    <div className="container">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="tt-ourproducts-description d-flex flex-column justify-content-center align-items-center bg bg-success p-3 p-md-5 mb-5">
                                <h1 className="fw-bold tt-header-fs">OUR <span>PRODUCT</span></h1>
                                <p className=" my-3" >Delivering the luxury of handmade cocktails in the convenience of a can, we have a fantastic lineup of cocktails
                                    which
                                    are portable and shelf stable. Whether on the sand at the beach, on the mountain, at a festival, or casually
                                    with a meal
                                    at home, you can Thirst Trap your way.</p>
                            </div>
                            <div className="row ">
                                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center ">
                                    <div className="tt-ourproducts-icon d-flex justify-content-center align-items-center bg bg-success p-3"><img src={require("../assets/imgs/home/icon3.png")}  className="img-fluid" alt="" /></div>
                                    <p className="text-dark mt-4 fw-bold">Premium</p>
                                    <p className="text-center text-white px-5" >className aptent taciti sociosqu ad litora torquent</p>
                                </div>
                                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                                    <div className="tt-ourproducts-icon d-flex justify-content-center align-items-center bg bg-success p-4"><img src={require("../assets/imgs/home/icon1.png")}  className="tt-icon-img  img-fluid" alt="" /></div>
                                    <p className="text-center text-dark mt-4 fw-bold">Made with Natural Flavors</p>
                                    <p className="text-centert text-white px-5" >className aptent taciti sociosqu ad litora torquent</p>
                                </div>
                                <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                                    <div className="tt-ourproducts-icon d-flex justify-content-center align-items-center bg bg-success p-3"><img src={require("../assets/imgs/home/icon2.png")} className="img-fluid"  alt="" /></div>
                                    <p className="text-dark mt-4 fw-bold">Refreshing</p>
                                    <p className="text-center text-white px-5" >className aptent taciti sociosqu ad litora torquent</p>
                                </div>
                            </div>
                        </div>
                    </div>``
                </section>
            
                <section id="slogan">
                    <div className="container">
                        <div className="row">
                            <h1 className="text-center fw-bold my-lg-5 mx-0 mx-lg-5 d-block d-lg-none">THIRST TRAP <span className="">YOUR WAY</span></h1>
                            <div className=" tt-slogan-card-container order-2 col-4 d-flex flex-column justify-content-end align-items-center mt-5 mt-lg-5">
                                <h1 className="text-center fw-bold my-lg-5 d-none d-lg-block tt-header-fs-slogan">THIRST TRAP <span className="">YOUR WAY</span></h1>
                                <img src={require("../assets/imgs/home/slogan_one.png")} className="tt-slogan-img img-fluid border border-dark border-2" alt="" />
                                <p className="fw-bold text-center text-dark mt-4">For <span>Anywhere</span></p>
                            </div>
                            <div className="tt-slogan-card-container order-1 col-4 d-flex flex-column justify-content-center justify-content-lg-start align-items-center mb-5 mb-lg-0">
                                <img src={require("../assets/imgs/home/slogan_three.png")} className="tt-slogan-img img-fluid border border-dark border-2"  alt="" />
                                <p className="fw-bold text-center text-dark mt-4">For <span>Anyone</span></p>
                            </div>
                            <div className="tt-slogan-card-container order-3 col-4 d-flex flex-column  justify-content-center justify-content-lg-start align-items-center mb-5 mb-lg-0">
                                <img src={require("../assets/imgs/home/slogan_two.png")} className="tt-slogan-img img-fluid border border-dark border-2" alt="" />
                                <p className="fw-bold text-center text-dark mt-4">For <span>Anytime</span></p>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-center">
                            <Link to="/about"><button className="tt-button btn">Learn</button></Link>
                        </div>
                    </div>
                </section>

                <section id="partners">
                    <div className="tt-partners-container bg-success container py-5 d-flex flex-column justify-content-center align-items-center border border-dark border-2 rounded-2">
                        <h1 className="text-center fw-bold mb-5 tt-header-fs">OUR <span>PARTNERS</span></h1>
                        <div className=" p-4 row row-cols-2 row-cols-lg-5 gy-3">
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/626_night_market_logo.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/cult_logo.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/duffl_logo-transformed.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/jaja_logo.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/lanea_logo-transformed.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/liquidIV_logo-transformed.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/rosewood_miramar_beach_logo-transformed.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/tequila_patron_logo-transformed.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/tinder_logo-transformed.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                                <div className="col d-flex justify-content-center align-items-center">
                                    <img src={require("../assets/imgs/partners/waldorf_astoria_logo.png")} className="tt-partners-img img-fluid" alt=""/>
                                </div>
                        </div>
                    </div>
                </section>

                <SwiperCarousel>
                    <SwiperNav />
                </SwiperCarousel>
                
            </main>

        </div>

    )
}

export default Home
