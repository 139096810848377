import { Link } from "react-router-dom"
import { SwiperSlide, Swiper, useSwiper } from "swiper/react";
import BestSellers from "../../cards/bestsellerscard";

const BestSellersCarousel = ({children, fanFavs}) => {
    const bestSellersID = ["WATSppHX90p15bwW4kN2", "EXiBv4wWA55MIWPp2suQ", "imOO28zoK620o3ipY1Nu"]
    const swiper = useSwiper() 

    return (
        <section id="">
            <div className="container">
                <div className="d-flex flex-column justify-content-center align-items-center mb-5">
                    <h2 className="tt-header-fs fw-bold">WHAT'S <span>HOT</span></h2>
                </div>
                <Swiper
                    loop={true}
                    breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                    }}
                    className="mySwiper2"
                >
                {fanFavs && fanFavs.map(product => (
                    <SwiperSlide key={product.id}>
                        <BestSellers  product={product} />
                    </SwiperSlide>
                ))}
                    {children}
                </Swiper>
                <div className="d-flex flex-column justify-content-center align-items-center mb-5">
                    <Link to="/products"><button className="tt-button btn">View All Products</button></Link>
                </div>
            </div>
        </section>
    )
}

export default BestSellersCarousel