import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ProductsData } from "../../data/productsdata"

const SingleProduct = ({products}) => {
    const { id } = useParams()
    const product = products.find((product) => product.id === id)
    const [image, setImage] = useState("")

    useEffect(() => {
        product && setImage(product.img_main)
    },[])

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-lg-6  mb-4 mb-lg-0">
                    <div className="row ">
                        <div className="col-3 ">
                            <div className="tt-single-product-thumbnails row d-flex flex-row gy-3 ">
                                <div className="tt-display-imgs d-flex justify-content-center align-items-center my-2" onClick={() => setImage(product && product.img_main)}>
                                    <img  src={product && product.img_main} className=" img-thumbnail img-fluid border-0 rounded-2" />
                                </div>
                                <div className="tt-display-imgs d-flex justify-content-center align-items-center my-2" onClick={() => setImage(product && product.img_supp_one)}>
                                    <img  src={product && product.img_supp_one} className=" img-thumbnail img-fluid border-0 rounded-2" />
                                </div>
                                <div className="tt-display-imgs d-flex justify-content-center align-items-center my-2" onClick={() => setImage(product && product.img_supp_two)}>
                                    <img  src={product && product.img_supp_two} className=" img-thumbnail img-fluid border-0 rounded-2" />
                                </div>
                                <div className="tt-display-imgs d-flex justify-content-center align-items-center my-2" onClick={() => setImage(product && product.img_supp_three)}>
                                    <img  src={product && product.img_supp_three} className="img-thumbnail img-fluid border-0 rounded-2" />
                                </div>
                            </div>
                        </div>
                        <div className="col-9 d-flex justify-content-center align-items-center">
                            <img src={image} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                    <div className="p-0 p-lg-5 d-flex flex-column justify-content-center align-items-start">
                        <h2 className="" >{product && product.name} </h2>
                        <h4 className="fw-light text-muted">{product && product.type}</h4>
                        <p className="text-muted">10% ALC./VOL. | 12FL.OZ</p>
                        <p className="tt-single-product-info">{product && product.description}</p>
                        <p className="text-muted">{ product && product.ingridients}</p>
                        <Link to="/storelocator"><button className="tt-button btn">Find Near You</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleProduct