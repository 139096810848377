import Maps from "../components/storelocator/maps"
import Panel from "../components/storelocator/panel"
import { collection, getDocs } from "firebase/firestore" 
import { useEffect, useState } from "react"
import { db } from "../firebase/firebase"

const StoreLocator = ({children}) => {
    const [currIndex, setCurrIndex] = useState(null)
    const [zipcode, setZipcode] = useState("")
    const [lat, setLat] = useState(null)
    const [lng, setLng] = useState(null)
    const [stores, setStores] = useState(null)
    const [foundStores, setFoundStores] = useState(null)
    const [center, setCenter] = useState({ lat: 34.0218588, lng: -118.5013979})


    useEffect(() => {
        async function queryStores () {
            setStores([])
            const data = await getDocs(collection(db, "stores"))
            data.docs.map((doc) => setStores(prevStores => [...prevStores, doc.data()]))
            // setStores(data.docs.map(doc => {return {...doc.data(), id:doc.id}}))
        }
         queryStores()
    },[])

    return ( 
        <section className="mt-5">
            <div className="mb-5 d-flex flex-column justify-content-center align-items-center">
                <h1 className="tt-header-fs fw-bolder text-center">WHERE TO  <span>FIND US</span></h1> 
                {/* <a href="https://orderthirsttrap.com/package-detail/" target="_blank"><button className="btn tt-button">Order Now</button></a> */}
            </div>
            <Maps 
                currIndex={currIndex}
                setCurrIndex={setCurrIndex}
                center={ center }
                setCenter={ setCenter }
                stores={ stores }
                foundStores={ foundStores }
                setFoundStores={ setFoundStores }
            >
                <Panel 
                    currIndex={currIndex}
                    setCurrIndex={setCurrIndex}
                    zipcode={ zipcode }
                    setZipcode={ setZipcode }
                    setLat= { setLat }
                    setLng = { setLng }
                    setCenter={ setCenter } 
                    stores={ stores }
                    setStores={ setStores }
                    foundStores={ foundStores }
                    setFoundStores={ setFoundStores }
                />
            </Maps>
        </section>
    )
}

export default StoreLocator