import { useSwiper } from 'swiper/react'

const SwiperNav = () => {
    const swiper = useSwiper() 

    return (
        <div className="swiper-nav-btns my-5 mx-1">
            <button className="me-4" onClick={() => swiper.slidePrev()} ><i className="bi bi-chevron-left fs-5"></i></button>
            <button onClick={() => swiper.slideNext()} ><i className="bi bi-chevron-right fs-5"></i></button>
        </div>
    )
}

export default SwiperNav