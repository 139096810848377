import GoogleMapReact from "google-map-react" 
import { useState } from "react"
import { StoresData } from "../../data/storesdata";
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  height: '750px'
};

const onLoad = infoWindow => {
  console.log('infoWindow: ', infoWindow)
}


const Maps = ({children, center, setCenter, stores, currIndex, setCurrIndex, foundStores, setFoundStores}) => {
    const [active, setActive] = useState(false)
    const [id, setId] = useState("")
    const [infoWindow, setInfoWindow] = useState("")
    const [isOpen, setIsOpen] = useState(false)


    function details (store, id) { 
        setActive(!active) 
        setInfoWindow(store)
        setId(id)
        setIsOpen(true)
        setCenter({lat: store.coordinates._lat, lng:store.coordinates._long })
        setCurrIndex(id)
    }
 
    return (
        // <div className="tt-maps">
        //     <div className="tt-react-maps mb-4">
        //         <GoogleMapReact
        //             bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API }}
        //             center={center}
        //             zoom={14}
        //             yesIWantToUseGoogleMapApiInternals
        //             onGoogleApiLoaded={({ map, maps }) => {
        //                 map.setClickableIcons(false)
        //             }}
        //             >
        //             {StoresData && StoresData.map((store, index) => (
        //                 <div key={index}  lat={store.coordinates._lat} lng={store.coordinates._long} className="tt-info-marker">
        //                     {store.name.includes("626") ?
        //                     <img src={store.img} alt="" className="tt-pins tt-626-box" onClick={() => details(store, index)}/> :
        //                     <i className="tt-pins bi bi-geo-alt-fill fs-2" onClick={() => details(store, index)}>
        //                     </i>}
        //                     {(id === index) && <InfoWindow infoWindow={infoWindow} isOpen={isOpen} setIsOpen={setIsOpen}/>}
        //                 </div>
                        
        //             ))}

        //         </GoogleMapReact>
        //     </div>
        //     {children} 
        // </div>
        <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAPS_API}
      >
        <div className="tt-remove-img-css">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}


                >
                        {foundStores ? foundStores.map((store, index) => (
                        <Marker  
                            key={index} 
                            className=""  
                            onClick={() => {
                                setCurrIndex(index)
                                setCenter({lat: Number(store.lat), lng: Number(store.lng) })
                            }}
                            position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                            icon={{
                            url:  store.name.includes("626") ? require("./../../assets/imgs/maps-626-icon2.png") : require("./../../assets/imgs/map-icon.png"),
                            scale: 15,
                            }} >
                                { currIndex === index  && <InfoWindow 
                                    position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                                    onCloseClick={() => setIsOpen(false)}
                                >
                                    <div className="tt-details-card-two">
                                        <div className="d-flex justify-content-between py-1">
                                            <p className="fw-bold fs-6 m-0 text-dark">{store.name}</p>
                                        </div>
                                        <p className="fs-6 text-muted mb-2">{store.address}</p>
                                        <a  className="fs-6" href={store.link} target="_blank">Website</a>
                                    </div>
                                </InfoWindow>}
                        </Marker>

                    )) : 
                    stores && stores.map((store, index) => (
                        <Marker  
                            key={index} 
                            className=""  
                            onClick={() => {
                                setCurrIndex(index)
                                setCenter({lat: Number(store.lat), lng: Number(store.lng) })
                            }}
                            position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                            icon={{
                            url:  store.name.includes("626") ? require("./../../assets/imgs/maps-626-icon2.png") : require("./../../assets/imgs/map-icon.png"),
                            scale: 15,
                            }} >
                                { currIndex === index  && <InfoWindow 
                                    position={{lat: Number(store.lat), lng: Number(store.lng)}} 
                                    onCloseClick={() => setIsOpen(false)}
                                >
                                    <div className="tt-details-card-two">
                                        <div className="d-flex justify-content-between py-1">
                                            <p className="fw-bold fs-6 m-0 text-dark">{store.name}</p>
                                        </div>
                                        <p className="fs-6 text-muted mb-2">{store.address}</p>
                                        <a  className="fs-6" href={store.link} target="_blank">Website</a>
                                    </div>
                                </InfoWindow>}
                        </Marker>

                    ))}
            </GoogleMap>
            {children}
        </div>
      </LoadScript>
    )
}

export default Maps