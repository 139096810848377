const LocationCard = ({store, setCenter, currIndex, setCurrIndex, index}) => {
    return (
        <div className="tt-card-container mb-4 px-1 border border-dark border-2 rounded-2 bg-white"  onClick={() => {
            setCurrIndex(index)
            setCenter({lat: Number(store.lat), lng:Number(store.lng)})
        }}>
            <div className="row">
                <div className="col-4 d-flex justify-content-center align-items-center">
                    { store.image ? <img src={store.image} className="img-thumbnail border border-0 bg-transparent" /> : 
                    <i className="bi bi-geo-alt-fill fs-1" >
                            </i>}
                </div>
                <div className="col-8">
                    <div className="p-1">
                        <h6 className="fw-bold">{store.name}</h6>
                        <p className="text-muted">{store.address}</p>
                        <div className="d-flex justify-content-between">
                            <p>{store.distance} Miles</p>
                            <a className="text-decoration-none text-dark" href={store.link} target="_blank">Website</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocationCard