import { Link } from "react-router-dom"

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <h1 className="tt-header-fs fw-bolder text-center mb-5">Privacy  <span>Policy</span></h1> 
            <p className="text-center">Effective: <span className="fs-bold text-dark">October 1st, 2024</span></p>
            <section className="d-flex  flex-column justify-content-center align-items-center">
            <div className="tt-policy">
                <p className="">
                    We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the enjoythirsttrap.com website (“Website” or “Service”) and any of its related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.
                </p>
                <p className="">
                    This Policy is a legally binding agreement between you (“User”, “you” or “your”) and QuickServe Cocktails LLC (“QuickServe Cocktails LLC”, “we”, “us” or “our”). If you are entering into this Policy on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Policy, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Policy, you must not accept this Policy and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.
                </p>
            </div>

            <div className="tt-policy mt-5">
                <h1 className="align-start">Collection of information</h1>

                <p className="">
                    Our top priority is customer data security and, as such, we exercise the no logs policy. We may process only minimal user data, only as much as it is absolutely necessary to maintain the Website and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Website and Services. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.
                </p>
            </div>

            <div className="tt-policy mt-5">
                <h1 className="">Use and processing of collected information</h1>

                <p className="">
                    We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.
                </p>
                <p>
                    Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Website and Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.
                </p>
                <p>
                    We act in the capacity of a data processor in situations when you submit Personal Information through the Website and Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.
                </p>
                <p>
                    In order to make the Website and Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:
                </p>
                <p>
                    <ul className="ms-5">
                        <li>Send product and service updates</li>
                        <li> Request user feedback</li>
                        <li> Improve user experience</li>
                        <li> Respond to legal requests and prevent harm</li>
                        <li> Run and operate the Website and Services</li>
                    </ul>
                </p>
                <p>
                    Processing your Personal Information depends on how you interact with the Website and Services, where you are located in the world and if one of the following applies: (a) you have given your consent for one or more specific purposes; (b) provision of information is necessary for the performance of this Policy with you and/or for any pre-contractual obligations thereof; (c) processing is necessary for compliance with a legal obligation to which you are subject; (d) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (e) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.
                </p>
                <p>
                    We rely on user’s consent as a legal base upon which we collect and process your Personal Information.
                </p>
                <p>
                    Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases above. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                </p>
                
            </div>

            <div className="tt-policy mt-5">
                <h1>Disclosure of information</h1>
                <p>To maintain the highest level of privacy and to protect your Personal Information to the full extent, we do not share your Personal Information with anyone and for any reason.</p>
                <p>Retention of information</p>
                <p> We will retain and use your Personal Information for the period necessary as long as your user account remains active, to enforce our Policy, resolve disputes, and unless a longer retention period is required or permitted by law.</p>
                <p>We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.</p>
                <p>Transfer of information</p>
                <p>Depending on your location, data transfers may involve transferring and storing your information in a country other than your own. However, this will not include countries outside the European Union and European Economic Area. If any such transfer takes place, you can find out more by checking the relevant sections of this Policy or inquire with us using the information provided in the contact section. Note that we are dedicated to ensuring the security of your personal data, adhering strictly to the guidelines outlined in our privacy notice and conforming to the applicable legal requirements.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Region specific notices</h1>
                <p>Out of respect for your privacy, we have implemented additional measures to comply with the obligations and rights associated with the collection of Personal Information as dictated by the laws governing the regions of our users.</p>

                <h2>- Disclosures for residents of Australia</h2>
                <p> If you are a resident of Australia, you have certain rights in relation to your Personal Information based on the Australian Privacy Act 1988 (“Privacy Act 1988”) that we comply with as part of our commitment to your privacy. This supplemental section, together with other relevant sections of the Policy, provides information about your rights and how to exercise them. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the Privacy Act 1988.</p>

                <p>(a) Right to access and correct: You have the right to access Personal Information we hold about you. You also have the right to request corrections to your Personal Information if you think the information is inaccurate, out-of-date, incomplete, irrelevant or misleading.</p>

                <p>(b) Right to restrict processing: You can request that we stop or restrict the processing of your Personal Information in certain circumstances, such as when you contest the accuracy of your data.</p>

                <p>(c) Right to data portability: You have the right to request the transfer of your Personal Information to a different service provider as long as it is technically possible to do so or directly to you.</p>

                <p>(c) Right to data portability: You have the right to request the transfer of your Personal Information to a different service provider as long as it is technically possible to do so or directly to you.</p>

                <p>(e) Right to anonymity: You are generally able to use a pseudonym or remain anonymous when interacting with us. However, in some circumstances you may have to provide certain Personal Information. For example, we may require Personal Information to assess your eligibility for a program or service. We will inform you if you are not able to remain anonymous or use a pseudonym when dealing with us.</p>

                <h2>- Disclosures for residents of Brazil</h2>
                <p>If you are a resident of Brazil, you have certain rights in relation to your Personal Information based on the Brazilian General Data Protection Law (“LGPD”) that we comply with as part of our commitment to your privacy. This supplemental section, together with other relevant sections of the Policy, provides information about your rights and how to exercise them. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the LGPD.</p>
                <p>(a) Right to know and access: You have the right to confirm whether we process your Personal Information and, if so, access it. This ensures you are fully informed about the data we hold and how it's used.</p>
                <p>(b) Right to correct: If you have the right to correct Personal Information iIf you find any of it in our possession to be incorrect or outdated as we are committed to maintaining the accuracy and relevance of your Personal Information.</p>
                <p>(c) Right to anonymize and block: You can request the anonymization or blocking of Personal Information that is unnecessary, excessive, or not processed in compliance with the LGPD.</p>
                <p>(d) Right to data portability: You have the right to transfer your data to another service provider or product supplier, promoting your freedom to choose services without losing your data history.</p>
                <p>(d) Right to data portability: You have the right to transfer your data to another service provider or product supplier, promoting your freedom to choose services without losing your data history.</p>
                <p>(f) Right to information about third parties: You can ask about the third parties with whom we share your data, ensuring transparency in our data sharing practices.</p>
                <p>(g) Right to information on consent denial: You have the right to be informed about the consequences of not providing consent to make informed decisions about the use of your Personal Information.</p>
                <p>(h) Right to withdraw consent: You can withdraw your consent for data processing at any time to ensure control over your Personal Information.</p>
                <p>(i) Right to review automated decisions: You can request a review of decisions made solely based on automated processing of your data to ensure fairness and accuracy in processes that significantly impact you.</p>


                <h2>- Disclosures for residents of Canada</h2>
                <p>If you are a resident of Canada, you have certain rights in relation to your Personal Information based on the Personal Information Protection and Electronic Documents Act (“PIPEDA”) that we comply with as part of our commitment to your privacy. This supplemental section, together with other relevant sections of the Policy, provides information about your rights and how to exercise them. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the PIPEDA.</p>
                <p>(a) Right to access: You have the right to access the Personal Information we hold about you if you wish to review, verify, or correct your information.</p>
                <p>(b) Right to correct: We strive to maintain the accuracy of your Personal Information and will promptly make necessary corrections when you identify inaccuracies in your data.</p>
                <p>(c) Right to withdraw consent: You can withdraw your consent regarding the handling of your Personal Information at any time, subject to legal or contractual limitations.</p>
                <p>(d) Right to complain: You have the right to file a complaint with the Privacy Commissioner of Canada if you feel your Personal Information is being handled in a way that violates PIPEDA.</p>
                <p>(e) Right to challenge compliance: You can challenge our compliance with PIPEDA, including how we handle your Personal Information, consent, access requests, and how we respond to your correction requests.</p>
                <p>(f) Right to know about breaches: You have the right to be notified in cases of a security breach involving your Personal Information that poses a real risk of significant harm.
                </p>

                <h2>- Disclosures for residents of the EU/EEA and the UK</h2>
                <p>If you are a resident of the European Union (“EU”), the European Economic Area (“EEA”), or the United Kingdom (“EEA”), you have certain rights in relation to your Personal Information based on the the GDPR and the UK DPA that we comply with as part of our commitment to your privacy. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the GDPR and the UK DPA.</p>
                <p>(a) Right to withdraw consent: You have the right to withdraw consent where you have previously given your consent to the processing of your Personal Information. To the extent that the legal basis for our processing of your Personal Information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</p>
                <p>(b) Right to access: You have the right to learn if your Personal Information is being processed by us, obtain disclosure regarding certain aspects of the processing, and obtain a copy of your Personal Information undergoing processing.</p>
                <p>(c) Right to rectification: You have the right to verify the accuracy of your information and ask for it to be updated or corrected. You also have the right to request us to complete the Personal Information you believe is incomplete.</p>
                <p>(d) Right to object to the processing: You have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent. Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us, or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection.</p>
                <p>(e) Right to restrict to the processing: You have the right, under certain circumstances, to restrict the processing of your Personal Information. These circumstances include: the accuracy of your Personal Information is contested by you and we must verify its accuracy; the processing is unlawful, but you oppose the erasure of your Personal Information and request the restriction of its use instead; we no longer need your Personal Information for the purposes of processing, but you require it to establish, exercise or defend your legal claims; you have objected to processing pending the verification of whether our legitimate grounds override your legitimate grounds. Where processing has been restricted, such Personal Information will be marked accordingly and, with the exception of storage, will be processed only with your consent or for the establishment, to exercise or defense of legal claims, for the protection of the rights of another natural, or legal person or for reasons of important public interest.</p>
                <p>(f) Right to delete: You have the right, under certain circumstances, to obtain the erasure of your Personal Information from us. These circumstances include: the Personal Information is no longer necessary in relation to the purposes for which it was collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure such as where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, to exercise or defense of legal claims.</p>
                <p>(g) Right to data portability: You have the right to receive your Personal Information that you have provided to us in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance from us, provided that such transmission does not adversely affect the rights and freedoms of others.</p>
                <p>(h) Right to complaint: You have the right to complain to a data protection authority about our collection and use of your Personal Information. If you are not satisfied with the outcome of your complaint directly with us, you have the right to lodge a complaint with your local data protection authority. For more information, please contact your local data protection authority in the EU or the EEA. This provision is applicable provided that your Personal Information is processed by automated means and that the processing is based on your consent, on a contract which you are part of, or on pre-contractual obligations thereof.</p>

                <h2>- Disclosures for residents of New Zealand</h2>
                <p>If you are a resident of New Zealand, you have certain rights in relation to your Personal Information based on the New Zealand’s Privacy Act 2020 (“Privacy Act 2020”) that we comply with as part of our commitment to your privacy. This supplemental section, together with other relevant sections of the Policy, provides information about your rights and how to exercise them. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the Privacy Act 2020.</p>
                <p>(a) Right to Access: You have the right to access your Personal Information that we hold, enabling you to review and understand the data we have about you for transparency and accuracy.</p>
                <p>(b) Right to Correction: If your Personal Information is incorrect or outdated, you have the right to request its correction, ensuring that the information we hold is accurate and up-to-date.</p>
                <p>(c) Right to Make a Complaint: If you believe your privacy rights have been breached, you have the right to make a complaint to a data protection authority.</p>
                <p>(d) Right to Object to Automated Decision-making: You can object to decisions made solely on automated processing of your Personal Information that have significant effects on you, ensuring a fair and transparent decision-making process.</p>
                <p>(e) Right to Data Portability: Where applicable, you have the right to request the transfer of your Personal Information to another service provider if technically possible or directly to you.</p>
                <p>(f) Right to Anonymity and Pseudonymity: Where possible, you have the option to interact with us without revealing your identity or by using a pseudonym, offering flexibility and control over your personal engagement.</p>
                <p>(g) Notification of Data Breaches: In the event of a data breach that may harm your privacy, we will notify you promptly, taking immediate steps to mitigate any potential impacts.</p>

                <h2>- Disclosures for residents of the USA</h2>
                <p>If you are a resident of California, Colorado, Connecticut, Delaware, Iowa, Maryland, Utah, or Virginia, you have certain rights and we aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information. This supplemental section, together with other relevant sections of the Policy, provides information about your rights and how to exercise them under the California Consumer Privacy Act and the California Privacy Rights Act (collectively, “CCPA”), the Colorado Privacy Act (“CPA”), the Connecticut Data Privacy Act (“CDPA”), the Delaware Online Privacy and Protection Act (“DOPPA”), the Iowa Consumer Data Protection Act (“ICDPA”), the Maryland Personal Information Protection Act (“PIPA”), the Utah Consumer Privacy Act (“UCPA”), the Virginia Consumer Data Protection Act (“VCDPA”), and and any and all regulations arising therefrom. Unless otherwise expressly stated, all terms in this section have the same meaning as defined in the related state laws.</p>
                <p>In addition to the rights as explained in this Policy, if you provide Personal Information as defined in the statute to obtain Services for personal, family, or household use, you have the right to submit requests related to your Personal Information once a calendar year. Note that there are circumstances when we may not be able to comply with your request such as when we are not be able to verify your request or we may find that providing a full response conflicts with other legal obligations or regulatory requirements. You will be notified if it’s the case.
                </p>
                <p>(a) Right to know and right to access: You have the right to request certain information we have collected about you. Once we receive and confirm a verifiable request from you, we will disclose to you, to the extent permitted by law:</p>
                <p>
                    <ul>
                        <li>The specific pieces of Personal Information we hold about you.</li>
                        <li>The categories of sources from which Information about you is collected.</li>
                        <li>The purposes for collecting, selling, or sharing your Personal Information.</li>
                    </ul>
                </p>
                <p>You have the right to request that the Personal Information to be delivered in a format that is both portable and easily usable, as long as it is technically possible to do so.</p>
                <p>(b) Right to correct: You have the right to request that we correct your inaccurate Personal Information taking into account the nature of the Personal Information and the purposes of the processing of the Personal Information.</p>
                <p>(c) Right to delete: You have the right to request deletion of your Personal Information.</p>
                <p>(d) Right to opt-out of the sale and sharing: You have the right to opt-out of the sale of your Personal Information which may include selling, disclosing, or transferring Personal Information to another business or a third party for monetary or other valuable consideration.</p>
                <p>(e) Right to consent to or limit the use of your sensitive personal information: You have the right to consent to the use of your Sensitive Personal information and to direct us to restrict its use and disclosure solely to what is essential for carrying out or delivering the Services in a manner reasonably anticipated by an average user, or for certain business objectives as specified by law. However, we do not use Sensitive Personal Information for any purposes other than those legally permitted or beyond the scope of your consent.</p>
                <p>(f) Right to non-discrimination: You have the right to not be discriminated against in the Services or quality of Services you receive from us for exercising your rights. We may not, and will not, treat you differently because of your data subject request activity, and we may not and will not deny goods or Services to you, charge different rates for goods or Services, provide a different level quality of goods or Services, or suggest that we would treat you differently because of your data subject request activity.</p>
                <p>(g) Shine the Light: California residents that have an established business relationship with us have the right to know how their personal information is disclosed to third parties for their direct marketing purposes under California’s “Shine the Light” law, or the right to opt out of such practices.
                </p>
                <p>To exercise any of your rights, simply contact us using the details below. After we receive and verify your request, we will process it to the extent possible within our capabilities.</p>


            </div>

            <div className="tt-policy mt-5">
                <h1>How to exercise your rights</h1>
                <p>Any requests to exercise your rights can be directed to us through the contact details provided in this document. Please note that we may ask you to verify your identity before responding to such requests. Your request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person. If we receive your request from an authorized representative, we may request evidence that you have provided such an authorized representative with power of attorney or that the authorized representative otherwise has valid written authority to submit requests on your behalf.</p>
                <p> You must include sufficient details to allow us to properly understand the request and respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Cookies</h1>

                <p>Our Website and Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. If you choose to decline cookies, you may not be able to fully experience the features of the Website and Services.</p>
                <p>We may use cookies to collect, store, and track information for security and personalization, to operate the Website and Services, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Privacy of children</h1>

                <p>We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Website and Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Website and Services, please contact us to request that we delete that child’s Personal Information from our Services.</p>
                <p>We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Website and Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Do Not Track signals</h1>

                <p>Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from users who use or visit a website or online service as they move across different websites over time. The Website and Services do not track its visitors over time and across third-party websites. However, some third-party websites may keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you. For a description of Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you, visit internetcookies.com</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Email marketing</h1>

                <p>We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.</p>

                <p>In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly state who the e-mail is from and provide clear information on how to contact the sender. You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or by contacting us.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Links to other resources</h1>

                <p>The Website and Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Website and Services and to read the privacy statements of each and every resource that may collect Personal Information.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Information security</h1>

                <p>We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</p>

                <p>Therefore, while we strive to protect your Personal Information, you acknowledge that (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity, and privacy of any and all information and data exchanged between you and the Website and Services cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.</p>
            </div>

            <div className="tt-policy mt-5">
                <h1>Data breach</h1>

                <p>In the event we become aware that the security of the Website and Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.</p>

            </div>

            <div className="tt-policy mt-5">
                <h1>Changes and amendments</h1>

                <p>We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.</p>

                <p>An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.</p>

            </div>
            
            <div className="tt-policy mt-5">
                <h1>Acceptance of this policy</h1>

                <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.</p>

            </div>

            <div className="tt-policy mt-5">
                <h1>Contacting us</h1>

                <p>If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:</p>
                <p><a className="text-dark" href="https://enjoythirsttrap.com/contact">enjoythirsttrap.com/contact</a></p>
                <p>We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.</p>

            </div>

            </section>
        </div>

    )
}

export default PrivacyPolicy