import { useState } from "react"
import { object, string, number, date, InferType } from 'yup';
import { useFormik } from "formik"
import emailjs from '@emailjs/browser';


const Contact = () => {
    const apiKey =  process.env.REACT_APP_EMAIL_JS_KEY
    const service =  process.env.REACT_APP_EMAIL_JS_SERVICE
    const template = process.env.REACT_APP_EMAIL_JS_TEMPLATE
    const [submitted, setSubmitted] = useState(false)

    function onSubmit (values) {
        console.log(values)

        emailjs.send(service, template, values, apiKey)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        setSubmitted(true)
    }

    const validationSchema = object({
        name: string().required(),
        email: string().email().required(),
        phone: string(),
        subject: string().required(),
        message: string().required()
    })

    const formik =  useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            subject:"",
            message:"",
        },
        validationSchema: validationSchema,
        onSubmit,
    })

    return(
        <div className="container mb-5">
            <h1 className="fw-bolder text-center">CONTACT <span>US</span></h1>
            <div className="d-flex justify-content-center mt-5">
                    {submitted ? 
                        <div className="d-flex flex-column justify-content-center align-items-center px-5">
                            <p className="text-center"><i className="text-primary bi bi-check-circle"></i></p>
                            <h2 className="text-center">Thank you for contacting us</h2>
                            <p >THIRST TRAP</p>
                        </div>
                    :
                    <form onSubmit={formik.handleSubmit}   className="tt-form-container">
                        <div className="row mb-5">
                            <div className="col-6">
                                <label htmlFor="name" className="form-label">Full Name <span className="text-danger" >{formik.errors.name && formik.touched.name ? "*" : null}</span></label>
                                <input  name="name" type="text" className={`form-control border border-2 py-2 ${formik.errors.name && formik.touched.name ? `border-danger` : `border-dark`}`} 
                                value={formik.values.name} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="email" className="form-label">Email <span className="text-danger" >{formik.errors.email && formik.touched.email? "*" : null}</span></label>
                                <input  name="email" type="email" className={`form-control border border-2 py-2 ${formik.errors.email && formik.touched.email ? `border-danger` : `border-dark`}`}
                                value={formik.values.email} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-6">
                                <label htmlFor="phone" className="form-label">Phone</label>
                                <input  name="phone" type="text" className="form-control border border-dark border-2 py-2" placeholder="(Optional)" 
                                value={formik.values.phone} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                            </div>
                            <div className="col-6">
                                <label htmlFor="subject" className="form-label">Subject <span className="text-danger" >{formik.errors.subject && formik.touched.subject ? "*" : null}</span></label>
                                <input  name="subject" type="text" className={`form-control border border-2 py-2 ${formik.errors.subject && formik.touched.subject ? `border-danger` : `border-dark`}`}  
                                value={formik.values.subject} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}/>
                            </div>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="message">Message <span className="text-danger" >{formik.errors.message && formik.touched.message ? "*" : null}</span></label>
                            <textarea name="message" className={`form-control border border-2 py-2 ${formik.errors.message && formik.touched.message ? `border-danger` : `border-dark`}`}  rows="5" 
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}></textarea>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="tt-button btn" >Submit</button>
                        </div>
                    </form>} 
            </div>
        </div>
    )
}

export default Contact