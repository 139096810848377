import { useState } from "react"

const TeamCard = ({member}) => {
    const width = window.innerWidth
    const [flipped, setFlipped] = useState(false)

    return (
        <div className="tt-team-individual-card col-12 col-lg-4 d-flex  flex-column justify-content-center align-items-center position-relative" onMouseOver={() => setFlipped(true)} onMouseLeave={() => setFlipped(false)} onClick={() => width <= 768 && setFlipped(!flipped)}>
            {/* <div className="card tt-team-card p-0 border border-dark border-2 rounded-2" onMouseOver={() => setFlipped(true)} onMouseLeave={() => setFlipped(false)} onClick={() => width <= 768 && setFlipped(!flipped)} style={{boxShadow: `10px 10px ${member.shadow}`, backgroundColor: `${member.color}`}}>
    
                {flipped ? <img src={member.imgs.flipped} alt="" className="img-fluid tt-team-img border-0 rounded-0 border-bottom border-dark border-2"/> :
                    <img src={member.imgs.default} alt="" className="img-fluid tt-team-img border-0 rounded-0 border-bottom border-dark border-2"/>}

        
                <div className="py-2 text-center bg-white">
                    {flipped ? 
                        <div className="p-1">
                            <p className="text-start text-dark ">
                                <span className="fw-bold text-dark">Favorite Cocktail: </span>
                                {member.description.one_key}
                            </p>
                            <p className="text-start text-dark ">
                                <span className="fw-bold text-dark">Spirit Animal: </span>
                                {member.description.two_key}
                            </p>
                            <p className="text-start text-dark ">
                                <span className="fw-bold text-dark">Least Favorite Word/Phrase: </span>
                                {member.description.three_key}
                            </p>
                            <p className="text-start text-dark ">
                                <span className="fw-bold text-dark">If I could have one superpower: </span>
                                {member.description.four_key}
                            </p>
                        </div> :
                        <h4 className="py-2 fw-bold text-dark">{member.name}</h4>
                    }
                </div> 
            </div> */}
            {!flipped ?             <div className="card tt-team-card p-0 border border-dark border-2 rounded-2" style={{boxShadow: `10px 10px ${member.shadow}`, backgroundColor: `${member.color}`}}>
    
                <img src={member.imgs.default} alt="" className="img-fluid tt-team-img border-0 rounded-0 border-bottom border-dark border-2"/>

                <div className="py-2 text-center bg-white">
                        <h4 className="py-2 fw-bold text-dark">{member.name}</h4>
                </div> 
            </div> :
            
            <div className="card  tt-team-card p-0 border border-dark border-2 rounded-2" style={{boxShadow: `10px 10px ${member.shadow}`, backgroundColor: `${member.color}`}}>
                <img src={member.imgs.flipped} alt="" className="img-fluid tt-team-img-flipped border-0 rounded-0 border-bottom border-dark border-2"/>

        
                <div className="py-2 text-center bg-dark opacity-75 position-absolute h-100 w-100  ">
                        <div className="p-1 d-flex flex-column justify-content-center align-items-center h-100">
                            <p className="text-start text-white text-center">
                                <span className="d-block fw-bold text-white text-center ">Favorite Cocktail: </span>
                                {member.description.one_key}
                            </p>
                            <p className="text-start text-white text-center">
                                <span className="d-block text-center fw-bold text-white">Spirit Animal: </span>
                                {member.description.two_key}
                            </p>
                            <p className="text-start text-white text-center">
                                <span className="d-block text-center fw-bold text-white">Least Favorite Word/Phrase: </span>
                                {member.description.three_key}
                            </p>
                            <p className="text-start text-white text-center">
                                <span className="d-block text-center fw-bold text-white">If I Could Have One Superpower: </span>
                                {member.description.four_key}
                            </p>
                        </div> 

                </div> 
            </div>

            }
        </div>
    )
}

export default TeamCard