import { useEffect } from "react"

const VendorForm = () => {
    useEffect(() => {
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSe2uSOYiAXblH7nQjx9lRPRJ--7s3nISWuo8B7PzfZEhE4LIA/viewform"
    },[])
    return(
        <h3 className="text-center my-5">Redirecting to order request form...</h3>
    )
}

export default VendorForm