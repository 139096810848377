import { useState } from "react"
import TeamCard from "../cards/teamcard"
import { TeamData } from "../data/teamdata"

const Team = () => {

    return (
        <section>
            <div className="container mt-5">
                <div className="mb-5">
                    <h1 className="tt-header-fs fw-bolder text-center">THE <span className="tt-green-color"> CREW</span></h1>
                </div>
                <div className="row justify-content-evenly gy-5">
                    {TeamData.map((member) => (
                        <TeamCard key={member.id}  member={member} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Team