import { Link } from "react-router-dom"

const About = () =>  {
    return (
        <div className="container mt-5">
            <h1 className="tt-header-fs fw-bolder text-center">ABOUT <span className="tt-green-color"> US</span></h1>
            <div className="tt-section-one mt-5 d-flex flex-column align-items-center">
                <img src={require("../assets/imgs/about/aboutus_header.jpeg")}  className="w-100 border border-dark border-2 mb-5" alt="" />
                <div className="row mb-5">
                    <div className="col-lg-6 mb-5 d-flex justify-content-center flex-column align-items-center align-items-lg-start px-1 px-lg-5">
                        <h1 className="tt-header-fs fw-bold mb-4">WHO WE <span className="tt-green-color">ARE</span></h1>
                        <h1 className="tt-about-text mb-4 text-center text-lg-start">Premium, made with natural flavors, and exquisitely refreshing</h1>
                        <Link to="/team"> <button className="btn tt-button mb-4" >Meet the Crew</button> </Link>
                    </div>
                    <div className="col-lg-6">
                        <p className="px-0 px-lg-5">At Thirst Trap, we believe that everyone should have the freedom to enjoy a delicious cocktail just the way they like it. We've crafted our canned cocktails to cater to our customers’ diverse tastes and preferences, empowering them to savor the drinks wherever and whenever they choose. Whether you're an outdoorsy adventurer, a busy mom, a driven entrepreneur, or simply someone who appreciates the finer things in life, Thirst Trap is here to quench your thirst for an exceptional drinking experience.</p>
                        <p className="px-0 px-lg-5" >We understand that each person has their own unique style and desires when it comes to enjoying a refreshing cocktail which is why we strive for accessibility and individuality. That's why we've made it our mission to create a range of drinks that can be drinken your way to suit your mood, location, and occasion. With Thirst Trap, you're in control. Our cans are not just a beverage; they're a canvas for your creativity and self-expression.Whether you prefer to enjoy our cocktails over ice, mix them with your favorite spirits, or garnish them with your signature twist, Thirst Trap is designed to be.</p>
                        <p className="px-0 px-lg-5" >Thirst Trap Your Way. Cheers to your personal cocktail adventure!</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 mb-4 d-flex justify-content-center">
                        <img src={require("../assets/imgs/about/aboutus_section_one.jpeg")}  className="tt-about-ourhistory-img  img-fluid border border-dark border-2" alt="" />
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <h1 className="tt-header-fs fw-bold text-center mb-4" >OUR <span className="tt-green-color">TEAM</span></h1>
                        <h1 className="mb-4 w-75 text-center">Chefs, Bartenders, Restauranteurs</h1>
                        <p className="px-0 px-lg-5">We are a team made up of hospitality experts.  With combined experience of over 40 years, our team of bartenders, chefs, restaurateurs, caterers, and event planners curated the best natural ingredients to create a product that you’d normally find within a bar program a restaurant or hotel, which can be enjoyed at home, at the beach, at a festival or wherever you want. We’ve combined our gastronomic experience to elevate your canned cocktail experience.  All ready-to-drink when you are.</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1 px-1 px-lg-5">
                        <h1 className="tt-header-fs fw-bold text-center text-lg-start mb-4">HOW IT ALL <span className="tt-green-color">STARTED</span></h1>
                        <p className="pe-0 pe-lg-5">Our canned cocktails were ultimately the result of a desire to get customers a restaurant quality cocktail during the pandemic. When everything shut down and the only option available was takeout, our founders were determined to find a way to get people a high quality cocktail they could enjoy at home. While making that goal become a reality, we learned how to shelf stabilize our recipes, ultimately leading to a high quality premium cocktail that could be enjoyed anywhere. With the influx of positive feedback that followed, we realized we had come across something special, and have since curated our own line of Thirst Trap Cocktails available for purchase.
</p>
                    </div>
                    <div className="col-lg-6 d-flex flex-column justify-content-center order-1 order-lg-2 mb-4">
                        <div className="row mb-4">
                            <div className="col-5 d-flex justify-content-end align-items-end">
                                <img src={require("../assets/imgs/about/square_one.png")}  className="img-fluid border border-dark border-2" alt="" />
                            </div>
                            <div className="col-7">
                                <img src={require("../assets/imgs/about/square_two.png")}  className="img-fluid border border-dark border-2" alt="" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7">
                                <img src={require("../assets/imgs/about/square_three.png")}  className="img-fluid border border-dark border-2" alt="" />
                            </div>
                            <div className="col-5 d-flex justify-content-start align-items-start">
                                <img src={require("../assets/imgs/about/square_four.png")}  className="border border-dark border-2 img-fluid " alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About 
