import { useNavigate } from "react-router-dom"

// Google Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

const ProductsCard = ({product}) => {
    const navigate = useNavigate()
    const analytics = getAnalytics()

    const productAnalytics = (name) => {
        logEvent(analytics, "product_engagement", {
            products: name
        })
        logEvent(analytics, product)
        logEvent(analytics, "select_item", product)
    }

    return (
            <div className="col" onClick={() => productAnalytics(product.name)}>
                <div className="card d-flex justify-content-center align-items-center card border border-0">
                    <div className="tt-products-img-container d-flex justify-content-center align-items- border border-dark border-2 p-5 rounded-2"  onClick={() => navigate(`/products/${product.id}`)} style={{boxShadow: `10px 10px ${product.color}`}} >
                        <img src={product.img_main} alt="" className="card-img-top"/>
                    </div>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center px-0">
                        <h5 className="card-title text-center">{product.name}</h5>
                        <p className="card-text text-center px-0 px-md-5 px-lg-0">{product.ingridients} </p>
                    </div>
            </div>
            </div>
    )
}

export default ProductsCard