import { SwiperSlide, Swiper, useSwiper } from "swiper/react";

const SwiperCarousel = ({children}) => {
    const swiper = useSwiper()

    return (
        <section id="reviews">
            <div className="container">
                <div className="mb-5">
                    <h2 className="tt-header-fs fw-bold text-center">CUSTOMER <span>REVIEWS</span></h2>
                </div>
                <Swiper
                    breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide >
                        <div className="card tt-individual-reviews bg-secondary rounded-2 p-sm-4 p-3 border border-dark border-2 mx-md-0">
                            <div className="card-body p-0">
                                <p className="fs-1 fs-bolder text-dark"><i className="bi bi-quote"></i></p>
                                <h2  className="text-dark">The best spicy margarita I’ve ever had!</h2>
                            </div>
                            <div className="card-footer border-0 bg-secondary">
                                <p className="text-dark"> - 626 Night Market Attendee</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className="card tt-individual-reviews bg-success rounded-2 p-sm-4 p-3 border border-dark border-2 mx-md-0">
                        <div className="card-body p-0">
                            <p className="fs-1 fs-bolder text-dark"><i className="bi bi-quote"></i></p>
                            <h2 className="text-dark">This is the first thing I have found that sorority girls like more than elevated surfaces.</h2>
                        </div>
                        <div className="card-footer border-0 bg-success">
                            <p className="text-dark"> - Brad</p>
                        </div>
                    </div>
                    </SwiperSlide>
                    <SwiperSlide>    
                    <div className="card tt-individual-reviews bg-info rounded-2 p-sm-4 p-3 border border-dark border-2 mx-md-0">
                        <div className="card-body p-0">
                            <p className="fs-1 fs-bolder text-dark"><i className="bi bi-quote"></i></p>
                            <h2 className="text-dark" >Where can we get these?!</h2>
                        </div>
                        <div className="card-footer border-0 bg-info">
                            <p className="text-dark">- Everyone</p>
                        </div>
                    </div></SwiperSlide>
                    <SwiperSlide >
                        <div className="card tt-individual-reviews bg-secondary rounded-2 p-sm-4 p-3 border border-dark border-2 mx-md-0">
                            <div className="card-body p-0">
                                <p className="fs-1 fs-bolder text-dark"><i className="bi bi-quote"></i></p>
                                <h2  className="text-dark">These cans have made my wife happy. Which means they have made me happy. </h2>
                            </div>
                            <div className="card-footer border-0 bg-secondary">
                                <p className="text-dark">- Jerry</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    {children}
                </Swiper>
            </div>
        </section>
    )
}

export default SwiperCarousel