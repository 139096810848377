import ProductsCard from "../cards/productscard"
import { ProductsData } from "../data/productsdata"
import { collection, doc, setDoc } from "firebase/firestore"
import { useState } from "react"
import { db } from "../firebase/firebase"


const Products = ({sortedProducts}) =>  {  
    const [producs, setProducts] =  useState([])
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] =  useState("")

    const joinUpdates = async () => {
        const emailRef = doc(collection(db, "emails"))
        try{
            setSubmitted(true)
            await setDoc(emailRef, {
                email
            })
            setEmail("")
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <section className="mt-5">
            <div className="container">
                <div className="mb-5">
                    <h1 className="tt-header-fs fw-bolder text-center">OUR <span>PRODUCTS</span></h1>
                </div>
                <div className="row row-cols-lg-3  row-cols-2 gy-5 justify-content-center">
                    {sortedProducts.map((product, index)=> (
                    <ProductsCard key={product.id} product={product} />
                    ))}
                    <div className="col-lg-8 col-12">
                        <div className="tt-more-to-come w-100 d-flex justify-content-center align-items-center">
                            {submitted ? 
                                <div className="border border-dark rounded-2 p-0 p-md-5 py-5 px-1 border-2 h-100 d-flex flex-column  align-items-center justify-content-center">
                                        <h2 className="fw-bold text-center mb-4">You have signed up for our updates!</h2>
                                    </div>:
                                    <div className="border border-dark rounded-2 p-0 p-md-5 py-5 px-1 border-2 h-100 d-flex flex-column  align-items-center justify-content-center">
                                        <h2 className="fw-bold text-center mb-4">MORE TO COME SOON...</h2>
                                        <div className="mt-5 d-flex flex-column flex-md-row w-100 justify-content-evenly" >
                                            <button className="btn bg-dark text-white mb-3 mb-sm-0" onClick={() => joinUpdates()}>Stay Updated</button>
                                            <input className="border-0 border-bottom border-2 bg-white text-center" type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Products