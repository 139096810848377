import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const Socials = () => {
    const [instaData, setInstaData] =  useState([])
    const [filteredImages, setFilteredImages] = useState([])
    const width = window.innerWidth 


    const instaAPI = process.env.REACT_APP_INSTA_API
    
    const url = `https://graph.instagram.com/me/media?fields=id,username,media_url&access_token=${instaAPI}`
    async function getInsta () {
        const response = await fetch(url)
        const data = await response.json()
        setInstaData(data.data)
    }

    const handleImageLoad = (event, url) => {
        const { naturalWidth, naturalHeight } = event.target;
        if (naturalWidth >= 1440) {
            setFilteredImages(prevFilteredImages => [...prevFilteredImages, url])
        } 
    };


    useEffect(() => {
        getInsta()
    }, [])

    return (
        <div id="socialmedia" >
            <div className="container h-100">
                <div className="row">
                    <div className="col-lg-4 order-lg-2">
                        <div className="tt-socialmedia-info d-flex justify-content-center align-items-center">
                            <div className="h-100 d-flex flex-column justify-content-evenly align-items-center">
                                <div className="">
                                    <h1 className="fw-bold text-center">FOLLOW <span>US</span> ON</h1>
                                    <div className="row justify-content-center">
                                        <div className="col d-flex justify-content-center align-items-center"><a href="https://www.facebook.com/profile.php?id=100092451355425" target="_blank"
                                                className="fs-2 text-dark"><i className="bi bi-facebook"></i></a></div>
                                        <div className="col d-flex justify-content-center align-items-center">
                                            <a href="https://www.instagram.com/enjoythirsttrap/" target="_blank" className="fs-2 text-dark">
                                                <i className="bi bi-instagram"></i>
                                            </a>
                                        </div>
                                        <div className="col d-flex justify-content-center align-items-center"><a href="https://x.com/i/flow/login?redirect_after_login=%2FEnjoythirsttrap" target="_blank"
                                                className="fs-2 text-dark"><i className="bi bi-twitter"></i></a></div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-end align-items-center">
                                    <p className="text-center text-dark">Want our product on your shelves?</p>
                                    <Link to="/contact"><button className="btn tt-button bg-white">Contact Us</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center order-lg-1">
                        <div className="tt-phone border border-2 border-dark bg-white ms-lg-5">
                            <div className="tt-phone-topbar  d-flex justify-content-center align-content-center">
                                <div className="tt-phone-topbar-content bg-dark">
                                </div>
                            </div>
                            <p className="fs-1 mx-4"><i className="bi bi-arrow-left-short"></i></p>
                            <div className="tt-phone-content d-flex flex-column justify-content-center align-items-center">
                                <a href="https://www.instagram.com/enjoythirsttrap/" target="_blank" className="text-decoration-none text-center">
                                    <img src={require("../assets/imgs/insta_logo.jpeg")} className="tt-phone-content-logo" alt="" />
                                    <h5 className="text-dark">THIRST TRAP</h5>
                                    <p className="fw-lighter">@enjoythirsttrap</p>
                                </a>
                                <div className="tt-phone-insta-content row row-cols-2 my-1 justify-content-center align-items-center gy-4 px-2 ">
                                    
                                    {instaData.length === 0 ? null : instaData.map((item) => (
                                        item.media_url.includes("mp4") ?  
                                        <div key={item.id} className=" px-2 mb-2" >
                                            <video className="img-fluid rounded-2" controls>
                                                <source  src={item.media_url} />
                                            </video>
                                        </div>
                                        : <div key={item.id} className="" >
                                            <img src={item.media_url} alt="" className="tt-phone-content-imgs img-fluid" 
                                                onLoad={(e) => {
                                                    handleImageLoad(e, item.media_url)
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-lg-3">
                        <div className="tt-phone-two border border-2 border-dark bg-white">
                            <div className="tt-phone-topbar d-flex justify-content-center align-content-center">
                                <div className="tt-phone-topbar-content bg-dark">
                                </div>
                            </div>
                            <p className="fs-5 mx-4 text-end"><i className="bi bi-bell-fill"></i></p>
                            <div className="tt-phone-content-two d-flex flex-column">
                                {filteredImages.length === 0 ? null : filteredImages.map((item, index) => (
                                    <div key={index} className="px-3 pb-3" >
                                        <img src={item} alt="" className=" img-fluid" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Socials